<template>
 <div class="overflow-hidden h-full bg-white py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
   <div
    class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
    <div class="lg:pr-8 lg:pt-4">
     <div class="lg:max-w-lg">
      <!-- <h2 class="text-base font-semibold leading-7 text-indigo-600">
       Deploy faster
      </h2> -->
      <p
       class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
       Bienvenue sur Expert Stats
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
       Expert Stats propose 3 analyses des flux d'appels pour améliorer la
       l’expérience client et la performance des équipes.
      </p>
      <dl
       class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
       <div
        v-for="feature in features"
        :key="feature.name"
        class="relative pl-9">
        <dt class="inline font-semibold text-teal-700">
         <component
          :is="feature.icon"
          class="absolute left-1 top-1 h-5 w-5 text-teal-600"
          aria-hidden="true" />
         {{ feature.name }}
        </dt>
        {{ " " }}
        <dd class="inline">{{ feature.description }}</dd>
       </div>
      </dl>
     </div>
    </div>
    <div class="rounded overflow-hidden shadow-lg bg-white">
     <iframe
      :src="youtubeEmbedUrl"
      class="w-full h-full"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>
     </iframe>
     <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">
       Découvrez en 3 min comment utiliser Expert Stats
      </div>
      <!-- <p class="text-gray-700 text-base">
       {{ description }}
      </p> -->
     </div>
     <!-- <div class="px-6 pt-4 pb-2">
      <a
       :href="youtubeUrl"
       target="_blank"
       class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
       >Watch on YouTube</a
      >
     </div> -->
    </div>
    <!-- <img src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png" alt="Product screenshot" class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" width="2432" height="1442" /> -->
   </div>
  </div>
 </div>
</template>

<script>
import {
 PhoneIncomingIcon,
 UsersIcon,
 UserGroupIcon,
} from "@heroicons/vue/solid";

const features = [
 {
  name: "Mes Numéros.",
  description:
   "Analysez tous les appels entrants de votre numéro de standard, du numéro d’un service ou d’un utilisateur.",
  icon: PhoneIncomingIcon,
 },
 {
  name: "Mes File d'attente.",
  description:
   "Analysez les appels entrants par file d'attente pour améliorer la gestion des temps d'attente et l'expérience client.",
  icon: UserGroupIcon,
 },
 {
  name: "Mes Utilisateurs.",
  description:
   "Analysez les stats individuelles des agents pour équilibrer la charge de travail et optimiser les performances.",
  icon: UsersIcon,
 },
];
export default {
 data() {
  return {
   features,
   youtubeUrl: "https://www.youtube.com/watch?v=WYeJY7OPemg",
  };
 },
 components: {
  PhoneIncomingIcon,
  UsersIcon,
  UserGroupIcon,
 },
 computed: {
  youtubeEmbedUrl() {
   const videoId = this.youtubeUrl.split("v=")[1];
   return `https://www.youtube.com/embed/${videoId}`;
  },
 },
};
</script>
